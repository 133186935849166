.Soil {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.Soil .SoilCol {
  width: 42%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.Soil .SoilCol:first-child {
  width: 58%;
}

.Soil .SoilRow.Bottom {
  height: 36%;
}

.Soil .SoilRow {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border-bottom: 5px solid white;
}

.Soil .SoilRow:last-child {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border-bottom: none;
}

.Soil .SoilRow.Top {
  height: 64%;
}

.Soil .SoilRow.Bottom {
  height: 36%;
}

.SoilSelectDiv {
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0px 20px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}

.SoilSelectDiv label {
  margin-top: 0;
  text-align: left;
}

.SoilMapDiv {
  height: calc(100% - 103px);
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.SoilDisplayDiv {
  height: 100%;
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
}

.SoilDisplayDiv .ActionsGraphicDiv {
  height: calc(100% - 100px);
  box-sizing: border-box;
  width: 50%;
  margin: 14px 0px 20px 0px;
}

.SoilTableDiv {
  height: calc(100% - 260px);
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.SoilButton {
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  letter-spacing: 0.7px;
  background: #8f807d;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}

.SoilButton:hover {
  color: white;
  background: #51534a;
}

.RemoveButton {
  color: #c00d1e;
  border-color: #c00d1e;
}

.RemoveButton:hover {
  color: white;
  background: #c00d1e;
}

.AddButton {
  color: #78be20;
  border-color: #78be20;
}

.AddButton:hover {
  color: white;
  background: #78be20;
}

.FlexP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.FlexP span {
  font-size: 13px;
  line-height: 10px;
}

.Paddock.soil {
  stroke: #51534a;
  fill: #f2f2f2;
  stroke-width: 1px;
}

.ChartHeading.soil {
  fill: #51534a;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.7px;
}

.SoilSelectDiv .ChartHeading {
  fill: #51534a;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.7px;
  text-align: center;
}

.SoilDateSelector {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SoilDateSelector .DateSelectorContainer {
  width: 100%;
  height: calc(100% - 63px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.SoilDateSelector .Arrow {
  padding: 20px;
  font-size: 2em;
  cursor: pointer;
}

.SoilDateSelector .Arrow.disabled {
  cursor: default;
  opacity: 0.3;
}

.SoilDateSelector .DateDisplay {
  /* width: 120px; */
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* align-items: center; */
  justify-content: space-between;
  background: #fcfcfc;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
}

.SoilDateSelector .DateDisplay label {
  /* background: #eee; */
  /* border-bottom: 2px solid #e8e8e8; */
  height: 100%;
  width: 100%;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px 0; */
}

.SoilDateSelector .DateDisplay .Month {
  font-size: 17px;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 15px 0;
  flex-grow: 1;
}

.SoilInput {
  width: 100%;
  height: 320px;
  /* background: #51534a; */
  box-sizing: border-box;
  padding: 0 10px 10px;
}

.SoilInput .InputContainer {
  width: 100%;
  height: calc(100% - 63px - 30px);
  display: flex;
}

.SoilInput .InputContainer .InputCol {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px 10px;
}

.SoilInput .InputContainer .InputCol .PaddockList {
  height: calc(100% - 30px - 20px - 30px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.SoilInput .InputContainer .InputCol .PaddockList .Paddock {
  background: #e8e8e8;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px 3px;
  /* height: 30px; */
  /* display: block;text-align: center; */
}

.SoilInput .InputContainer .InputCol .PaddockAreaDisplay {
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
}

/* .SoilInput .InputContainer .InputCol .PaddockList .Row:first-child {
  border-top: 2px solid #e8e8e8;
} */

.SoilInput .InputContainer .InputCol .Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.SoilInput .InputContainer .Button {
  width: 100%;
  margin: 0;
}

.SoilInput .SoilInputButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.SoilInput .SoilInputButtonContainer .Button {
  width: 30%;
  margin: 0 5px;
  background: #78be20;
}

.SoilInput .SoilInputButtonContainer .Button:first-child {
  background: #51534a;
}

.TableDiv {
  height: calc(100% - 22px);
  width: calc(50%);
  min-height: 78px;
  margin: 10px;
  overflow-y: auto;
  border: 1px solid white;
  border-radius: 5px;
  background: #f2f2f2;
}

.SelectedTable {
  border-collapse: collapse;
  width: calc(100%);
  text-align: center;
  color: white;
  font-size: 15px;
}
.SelectedTable.small {
  font-size: clamp(14px, 1vw, 20px);
}
.SelectedTable.small tr td:not(:first-child) {
  width: 1px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-align: center;
  border-left: 3px solid #f2f2f2;
}
.SelectedTable.small tr td:first-child {
  text-align: start;
  padding-left: 1em;
  padding-right: 1em;
}
.SelectedTable.small tr th:first-child {
  text-align: start;
  padding-left: 1em;
  padding-right: 1em;
}
.SelectedTable.small tr th div:not(:first-child) {
  padding-top: 0.3em;
}

tbody {
  color: #51534a;
}

.SelectedTable tr th {
  color: white;
  background: rgba(81, 83, 74, 0.8);
  font-weight: normal;
  font-size: 1em;
  padding: 0.4em;
}

.SelectedTable tr td {
  background: #f2f2f2;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 0.25em;
  font-size: 1em;
}

.SelectedTable tbody tr:nth-child(even) td {
  background: white;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
}
