.LoginWrapper {
  box-shadow: 2px 2px 8px darkgrey;
  width: 500px;
  max-width: 90vw;
  overflow-y: auto;
  margin-bottom: calc(60px + 1vw);
}

.LoginWrapper .LoginContainer {
  width: 500px;
  max-width: 90vw;
  border-color: #8f807d;
  border-style: solid;
  border-width: 0;
  background: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
}

.Main .LoginContainer {
  width: 100%;
  max-width: 90vw;
  height: calc(100%);
  border: none;
  border-width: 0 3px;
  background: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
}

.Login {
  width: 400px;
  max-width: calc(90vw - 60px);
  border-left: none;
  border-right: none;
  padding: 30px;
  position: relative;
  float: left;
}

.Login .Button {
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8f807d;
  border: none;
  cursor: pointer;
  margin: 20px 40px 0 40px;
  color: white;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.7px;
  margin-bottom: 20px;
  border: 1px solid rgba(81, 83, 74, 1);
  background-color: rgba(81, 83, 74, 0.7);
}

.Login .Button:hover {
  color: white;
  background: #51534a;
}

.Login .Button.inline {
  display: inline-flex;
  width: 50%;
  max-width: 45vw;
  margin: 0;
}

h3 {
  text-align: center;
  font-size: 18px;
  color: #51534a;
  margin: 30px 0px 30px 0px;
  position: static;
}

.Login .FormInput {
  text-align: center;
  font-size: 14px;
  color: #51534a;
  margin: 0px 0px 30px 0px;
}

p {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #51534a;
  margin: 10px 0px 10px 0px;
}

label {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #51534a;
  margin: 10px 0px 10px 0px;
}

.LoginNav {
  width: 500px;
  max-width: 90vw;
  min-height: 30px;
  display: flex;
}

.LoginButton {
  width: 250px;
  max-width: 45vw;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
  box-shadow: inset 1px -1px 2px lightgrey;
  box-sizing: border-box;
  border-color: #51534a;
  border-style: solid;
  border-width: 0px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
}

.LoginButton.Left {
  box-shadow: inset -1px -1px 2px lightgrey;
}

.LoginButton.Active {
  background: white;
  border-width: 3px 0 0 0;
  cursor: auto;
  box-shadow: none;
}

.Footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  min-height: 60px;
  margin-top: 60px;
  z-index: 2;
  background: white;
  border-top: 2px solid #51534a;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px darkgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LoginLogoLarge {
  width: 500px;
  max-width: 90vw;
  margin: 80px;
}

.Message {
  font-size: 20px;
  color: #51534a;
  font-weight: 600;
}
