.Calendar .CalContainer {
  padding: 10px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.Calendar .CalHeader {
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
}

.CalNav {
  cursor: pointer;
}

.CalBody {
  flex-grow: 1;
}

.CalBody table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  text-align: center;
  border-collapse: collapse;
}

.CalBody table thead {
  background: #eee;
  border: 2px solid #e8e8e8;
}

.CalBody table tbody {
  background: #fcfcfc;
  border: 2px solid #e8e8e8;
}

.CalBody table th,
.CalBody table td {
  padding: 2px 4px;
  border: 1px solid #e8e8e8;
}

.CalWeekend {
  background: #f6f6f6;
}

.CalSurvey {
  padding: 2px 0 3px;
  font-weight: bold;
  background: rgba(188, 224, 159, 0.4);
  background: rgba(120,190,32, 0.7);
  background: rgba(81, 83, 74, 0.25);
  color: #000;
  font-size: 1.1em;
  cursor: pointer;
}

.CalDisp {
  padding: 2px 0 4px;
  font-weight: bold;
  background: #51534a;
  background: rgba(120,190,32, 1);
  color: white;
  font-size: 1.3em;
  cursor: pointer;
}
