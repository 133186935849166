:root {
  /* Border colors */
  --border: #51534a;
  --border: #51534a;
  --border-light: #dcdcdc;
  /* Background colors */
  --bg-1: #ffffff;
  --bg-2: #f7f7f7;
  --bg-3: #eeeeee;
  /* Text colors */
  --text-light: white;
  --text-dark: #51534a;
  /* Grass colors */
  --grass-strong: #51534a;
  --grass-light: #78be20;
  --grass-lighter: #51534a;
  --grass-accent-light: #abc1ed;
  --grass-accent1: #4f596e;
  --grass-accent2: #0a3d54;
  --grass-accent3: #cccccc;
  --grass-excluded: #dd1010;
  /* Soil colors */
  --soil-strong: #ff773c;
  --soil-light: #f6fa47;
  --soil-accent1: #c72625;
  --soil-accent2: #7a3723;
  /* Neutral colors */
  --neutral-strong: #51534a;
  --neutral-light: #afbfcf;
  --neutral-accent1: #51534a;
  --neutral-accent2: #51534a;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--text-dark);
}
