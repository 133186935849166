.GroupSharing {
  padding: 16px;
  height: calc(100vh - 55px);
  box-sizing: border-box;
  background: #91938822;
  overflow-y: scroll;
}

.GroupSharing .FarmerContainer {
  padding: 16px;
  border-radius: 16px;
  background: white;
}
.GroupSharing .FarmerContainer:not(:first-child) {
  margin-top: 16px;
}

.GroupSharing .TextContainer {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 0.8em;
}
.GroupSharing .ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.GroupSharing .Button {
  font-size: 1.5em;
  color: white;
  height: 2em;
  width: 292px;
}
.GroupSharing .Button.Green {
  border: 1px solid #78be20;
  background-color: rgba(120, 190, 32, 0.8);
  transition: all 250ms;
}
.GroupSharing .Button.Green:hover {
  background-color: #78be20;
}
.GroupSharing .Button.Grey {
  border: 1px solid rgba(145, 147, 136, 1);
  background-color: rgba(145, 147, 136, 0.8);
  transition: all 250ms;
}
.GroupSharing .Button.Grey:hover {
  background-color: rgba(145, 147, 136, 1);
}

.GroupSharing .ErrorMessage {
  margin: 0 0 16px;
  font-weight: 500;
  color: #c00d1e;
  font-style: italic;
}

.GroupSharing .Button {
  margin: 0 8px;
}
.GroupSharing .Button:not(:first-child) {
  margin: 8px 0 0;
}
@media (min-width: 664px) {
  .GroupSharing .Button {
    margin: 0;
  }
  .GroupSharing .Button:not(:first-child) {
    margin: 0 0 0 16px;
  }
}

.GroupSharing .GroupForm .Button {
  margin: auto;
}
.GroupSharing label {
  font-size: 1.2em;
  line-height: 1.4em;
}

.GroupSharing .FormInput {
  font-size: 1.2em;
  text-align: center;
  height: 2em;
}

.GroupSharing .GroupForm .FormInput {
  margin-bottom: 0.8em;
  background-color: white;
  font-size: 1.5em;
  text-align: center;
  height: 1.5em;
}

.GroupSharing .FarmerContainer .GroupForm {
  max-width: 600px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 8px;
  background: #91938833;
  margin: 0.8em auto 0;
}

.GroupSharing .FarmerContainer .FarmerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 2px solid #51534a88;
}

.GroupSharing .FarmerContainer .FarmerHeader span {
  font-size: 1.5em;
  font-weight: 600;
  width: 33%;
  text-align: center;
}

.GroupSharing .FarmerContainer .FarmSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.GroupSharing .FarmerContainer .WedgeContainer {
  height: 300px;
  width: 33%;
  display: flex;
  flex-wrap: wrap;
}
