.App {
  width: 100%;
  height: 100vh;
  line-height: 100%;
  font-family: 'Roboto';
  display: flex;
  overflow: hidden;
}
a {
  color: #FFFFFF;
}
.Main {
  background: white;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: calc(100vw - 230px);
  width: calc(100vw - 230px);
  max-width: 100vw;
  width: 100vw;
}
.Main.mobile {
  background: white;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;
}

textarea {
  resize: none;
}