.Loading {
  width: 100%;
  height: calc((100vh - 55px));
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Loading p {
  text-align: center;
}

.Spinner {
  width: 50px;
  height: 50px;
  animation: rotation 500ms infinite linear;
}

.LargeSpinner {
  width: 100px;
  height: 100px;
  animation: rotation 500ms infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.ChartContainer {
  width: 100%;
  height: calc((100vh - 55px));
  position: relative;
  display: flex;
}
.ChartContainer.mobile {
  flex-direction: column;
}

.ChartCol {
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
}

.ChartCol.scroll {
  overflow-y: scroll;
}
.ChartCol.mobile {
  width: 100%;
}

.ChartCol:first-child {
  border-right: 1px solid rgba(145, 147, 136, 0.8);
}

.ChartRow {
  width: 100%;
  display: flex;
}

.ChartRow.Top {
  height: 64%;
  border-bottom: 5px solid white;
  box-sizing: border-box;
}

.ChartRow.Bottom {
  height: calc(36%);
}

.Chart {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: calc(50% - 35px);
  position: relative;
}

.Chart.fullheight {
  height: 100%;
}

.Chart.mobile {
  height: calc(50% - 90px);
}

#FarmMap {
  border-bottom: 1px solid rgba(145, 147, 136, 0.8);
}

.DashboardTabList {
  height: 35px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(145, 147, 136, 0.8);
  background: rgba(145, 147, 136, 0.3);
  display: flex;
  align-items: center;
}

.DashboardTabList .TabsLabel {
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: calc(100%);
  color: #000;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

.WedgeTab {
  background: rgba(255, 255, 255, 0.6);
  margin: 0px 2px;
  padding: 6px;
  letter-spacing: 0.3px;
  cursor: pointer;
  transition: all 250ms;
  border-radius: 3px;
}

.WedgeTab.active {
  color: white;
  background: rgba(81, 83, 74, 0.8);
}

.WedgeTab:hover {
  background: rgba(255, 255, 255, 1);
}

.WedgeTab.active:hover {
  background: #51534a;
}

.ScrollPanelItem {
  height: 250px;
  min-height: 250px;
}

.ScrollPanelItem.Row {
  display: flex;
}

.ScrollPanelHalfWidth {
  height: 250px;
  min-height: 250px;
  width: 50%;
}

.ScrollPanelItem.mobile {
  height: 180px;
}
.ScrollPanelItemHeader {
  padding: 8px 12px;
  border-top: 1px solid rgba(145, 147, 136, 0.4);
  border-bottom: 1px solid rgba(145, 147, 136, 0.4);
  background-color: rgba(145, 147, 136, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ScrollPanelItem:not(:nth-last-child(3)) .ScrollPanelItemHeader.Collapsed {
  border-bottom: none;
}

.ScrollPanelItem:nth-last-child(3) .MetricsPanel {
  border-bottom: 1px solid rgba(145, 147, 136, 0.4);
}

.ScrollPanelItemHeader h3 {
  color: #000;
}

.DropIcon {
  height: 16px;
  width: 16px;
  margin-left: 10px;
  filter: invert(1);
}
.DropIcon.Expanded {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.MetricGroup {
  display: flex;
  flex-wrap: wrap;
}

.MetricGroupHeader {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid rgba(145, 147, 136, 0.4);
  padding: 10px 2px 2px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
}

.Metric {
  border-radius: 5px;
  margin: 2px;
  padding: 6px;
  box-sizing: border-box;
  width: calc(50% - 4px);
}

.MetricGroup .Metric.Highlight {
  background: #51534a;
}

.MetricGroup .Metric.Highlight label {
  color: white;
}

.MetricGroup .Metric.Highlight .FormInput {
  background: #ffffffbb;
  font-weight: bold;
}

.OptionButton {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.FormGroup {
  padding: 10px;
  box-sizing: border-box;
}

@media (min-width: 1300px) {
  .WedgeContainer .Metric {
    width: 50%;
    width: calc(50% - 4px);
  }
  .MetricGroup .Metric {
    width: 33%;
    width: calc(33% - 4px);
  }
  .OptionButton {
    width: 50%;
  }
}

@media (min-width: 2100px) {
  .MetricGroup .Metric {
    width: 25%;
    width: calc(25% - 4px);
  }
  .OptionButton {
    width: 33%;
  }
}

.Metric .FormInput {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
}

.Chart:first-child {
  border-left: none;
}

.Chart.Full {
  width: 100%;
  height: calc(100%);
}

.Chart.FullWidth {
  width: 100%;
}

.OverflowHidden {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.tooltip {
  pointer-events: none;
  position: absolute;
  opacity: 0;
  background: #51534a;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  z-index: 5;
}

.tooltip::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -15px;
  bottom: -20px;
  left: 50%;
  box-sizing: border-box;
  border: 10.6px solid #51534a;
  border-color: transparent transparent #515858 #51534a;
  transform-origin: 0 0;
  transform: rotate(-45deg);
}

.LegendTitle {
  fill: #51534a;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.ChartHeading {
  fill: #51534a;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.7px;
  position: static;
}

.ChartHeading.small {
  font-weight: 600;
  font-size: 16px;
}

.PaddockName {
  fill: white;
  font-weight: bold;
  letter-spacing: 0.3px;
  font-size: 20px;

  font-size: 18px;
  paint-order: stroke;
  vector-effect: non-scaling-stroke;
  stroke: #51534a;
  stroke-width: 3px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  font-weight: 800;
}

.Popover_right {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: -263px;
  width: 260px;
  max-height: 100%;
  overflow-y: auto;
  background: white;
  transition: right 250ms;
  border-radius: 0 0 0 0;
  pointer-events: all;
}

.Popover_right.toggled {
  right: 0;
  border-left: 2px solid rgba(173, 160, 153, 0.4);
  border-bottom: 2px solid rgba(173, 160, 153, 0.4);
  border-top: 2px solid rgba(173, 160, 153, 0.4);
}

.PopoverToggle_right {
  box-sizing: border-box;
  position: absolute;
  font-size: 30px;
  color: #51534a;
  top: 5px;
  right: -2px;
  width: 30px;
  height: 30px;
  background: white;
  border: 2px solid rgba(173, 160, 153, 0.4);
  border-right: 0;
  transition: right 250ms;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  pointer-events: all;
}

.PopoverToggle_right.toggled {
  right: 258px;
}

.Popover_left {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: -263px;
  width: 260px;
  max-height: 100%;
  overflow-y: auto;
  background: white;
  transition: left 250ms;
  border-radius: 0 0 0 0;
  pointer-events: all;
}

.Popover_left.toggled {
  left: 0;
  border-right: 2px solid rgba(173, 160, 153, 0.4);
  border-bottom: 2px solid rgba(173, 160, 153, 0.4);
  border-top: 2px solid rgba(173, 160, 153, 0.4);
}

.PopoverToggle_left {
  box-sizing: border-box;
  position: absolute;
  font-size: 30px;
  color: #51534a;
  top: 5px;
  left: -2px;
  width: 30px;
  height: 30px;
  background: white;
  border: 2px solid rgba(173, 160, 153, 0.4);
  border-left: 0;
  transition: left 250ms;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  pointer-events: all;
}

.PopoverToggle_left.toggled {
  left: 258px;
}

.Popover_right .FormGroup {
  padding: 10px;
}

.Popover_right .FormGroup .Right {
  padding: 5px 0 0;
  margin: 0;
  text-align: right;
}

.Popover_right .FormGroup .Flex {
  padding: 5px 0 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.Popover_right .FormGroup .None {
  display: none;
}

.Popover_left .FormGroup {
  padding: 10px;
}

.Popover_left .FormGroup .Right {
  padding: 5px 0 0;
  margin: 0;
  text-align: right;
}

.Popover_left .FormGroup .Flex {
  padding: 5px 0 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.Popover_left .FormGroup .None {
  display: none;
}

.FormInput {
  display: block;
  width: 100%;
  height: 30px;
  font-size: 12px;
  color: #000;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid #51534a;
  border: 1px solid rgba(145, 147, 136, 0.8);
  border-radius: 3px;
  background-color: rgba(145, 147, 136, 0.1);
}
td .FormInput {
  text-align: center;
}

.FormInput:disabled {
  opacity: 0.5;
}

.FormInput.error {
  border-bottom: 3px solid #c72625;
  padding-bottom: 1px;
  opacity: 0.8;
}

.Radio {
  color: #7c807b;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.FormInputGroup {
  display: flex;
  align-items: center;
}

.Button {
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  font-weight: normal;
  letter-spacing: 0.7px;
  border: 1px solid rgba(81, 83, 74, 1);
  background-color: rgba(81, 83, 74, 0.7);
  text-align: center;
}
.Button.Green {
  border: 1px solid #78be20;
  background-color: rgba(120, 190, 32, 0.6);
  color: #000;
  transition: all 250ms;
}
.Button.Green:hover {
  background-color: #78be20;
  color: #fff;
}
.Button.Red {
  border: 1px solid #c00d1e;
  background-color: rgba(192, 13, 30, 0.4);
  color: #000;
  transition: all 250ms;
}
.Button.Red:hover {
  background-color: #c00d1e;
  color: #fff;
}
.Button.Grey {
  border: 1px solid rgba(145, 147, 136, 1);
  background-color: rgba(145, 147, 136, 0.7);
  transition: all 250ms;
}
.Button.Grey:hover {
  background-color: rgba(145, 147, 136, 1);
}
.Button.Action {
  border: 1px solid rgba(81, 83, 74, 1);
  background-color: rgba(81, 83, 74, 0.7);
  letter-spacing: 0.3px;
  cursor: pointer;
  transition: all 250ms;
}
.Button.Action:hover {
  background-color: rgba(81, 83, 74, 1);
}

.FormGroup .Button {
  margin: 20px 40px 0 40px;
}

.FormGroup .Button:hover {
  color: white;
  background: #51534a;
}

.InfoTableContainer {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InfoTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
}

.InfoTable tr:nth-child(odd) th {
  color: #51534a;
}

.InfoTable tr:nth-child(even) th {
  color: #51534a;
}

.InfoTable tr:nth-child(odd) td {
  color: #51534a;
}

.InfoTable tr:nth-child(even) td {
  color: #51534a;
}

.InfoTable tr th {
  text-align: right;
  padding: 3px 8px 3px 20px;
  width: 50%;
  border-radius: 10px 0 0 10px;
  font-weight: normal;
}

.InfoTable tr td {
  text-align: left;
  padding: 3px 20px 3px 8px;
  border-radius: 0 10px 10px 0;
  font-weight: normal;
}

.InfoTable tr th.InfoTableHeader {
  font-size: 20px;
  text-align: center;
  padding: 3px 8px;
  border-radius: 10px;
  font-weight: bold;
}

.FloatingSelect {
  position: absolute;
  left: 5px;
  top: 5px;
  display: flex;
  align-items: center;
}

#FarmMapSVG svg g rect {
  filter: drop-shadow(1px 1px 3px #00000055);
}

.Paddock {
  stroke: green;
  stroke: #51534a;
  fill: rgba(120, 190, 32, 0.6);
  stroke-width: 1px;
  filter: drop-shadow(1px 1px 3px #00000055);
}

.Paddock.unmeasured {
  fill: transparent;
  stroke-width: 1px;
}

.Paddock.excluded {
  stroke: #c00d1e;
  opacity: 0.5;
}

.Paddock.ignored {
  opacity: 0.5;
}

.Paddock.selector.active {
  stroke: #51534a;
  fill: #51534a;
}

.Paddock.selector:hover {
  stroke: #51534a;
  stroke-width: 2px;
}

.Point {
  stroke: #51534a;
  fill: rgba(120, 190, 32, 0.6);
  stroke-width: 1px;
  filter: drop-shadow(1px 1px 3px #00000055);
}

.Point.active {
  fill: rgba(255, 176, 0, 0.6);
}

.WedgeBar {
  stroke: #51534a;
  fill: rgba(120, 190, 32, 0.8);
  stroke-width: 1px;
  cursor: pointer;
  filter: drop-shadow(1px 1px 3px #00000055);
}

.WedgePadding {
  stroke: #ffffff;
  /* fill: #7dc243; */
  stroke-width: 2px;
  pointer-events: none;
}

.WedgeBar.active {
  fill: rgba(81, 83, 74, 0.6);
}

.WedgeBar.unmeasured {
  fill: rgba(120, 190, 32, 0.4);
}

.WedgeBar.unmeasured.active {
  fill: #4eafb7;
}

.WedgeBar.silage {
  stroke: #c00d1e;
  fill: #f2a90066;
}

.WedgeBar.active.silage {
  fill: rgba(81, 83, 74, 0.6);
}

.WedgeBar.unmeasured.silage {
  fill: #f2a90055;
}

.WedgeBar.unmeasured.active.silage {
  fill: #4eafb7;
}

.WedgeProj {
  stroke: none;
  fill: rgba(120, 190, 32, 0.4);
}

.WedgeProj.unmeasured {
  stroke: none;
  fill: none;
}

.WedgeLabel {
  fill: #51534a;
  text-anchor: start;
  pointer-events: none;
}

.WedgeLabel.active {
  fill: white;
  text-shadow: 0px 0px 2px #51534a;
}

.HistoryLine {
  stroke: #cccccc;
  fill: none;
  stroke-width: 2px;
}

.YTD {
  fill: rgba(120, 190, 32, 0.4);
}

.YTD.selected {
  fill: rgba(120, 190, 32, 1);
}

.Rotations {
  stroke: white;
  fill: rgba(120, 190, 32, 0.4);
  stroke-width: 2px;
}

.Rotations.complete {
  fill: rgba(120, 190, 32, 1);
}

.svgMessage {
  font-size: 20px;
  text-anchor: middle;
  fill: #51534a;
}

.GrowthArea {
  stroke: none;
  fill: rgba(120, 190, 32, 0.8);
  pointer-events: none;
  filter: drop-shadow(1px 1px 3px #00000055);
}

.GrowthCurve {
  stroke: #51534a;
  fill: none;
  stroke-width: 1px;
}
.GrowthCurve.Prev {
  stroke: #51534a88;
  stroke-dasharray: 5, 3;
  stroke-width: 2px;
}

.grid line,
.grid path {
  stroke: #d8d8d8;
}

.axis line,
.axis path {
  stroke: #ccc;
}

.axis text {
  fill: #51534a;
  font-family: 'Roboto';
  /* font-weight: bold; */
}

svg text {
  pointer-events: none;
}

.Difference {
  stroke: none;
  fill: rgba(242, 169, 0, 0.4);
}

.TextBackdrop {
  stroke: none;
  fill: rgba(242, 169, 0, 0.4);
  pointer-events: none;
}

.BlockTable {
  table-layout: fixed;
}

.ToggleContainer {
  background-color: #f2f2f2;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
}

.Toggle {
  background-color: #f2f2f2;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  width: 50%;
  padding: 4px 8px;
  text-align: center;
  border: 1px solid rgba(81, 83, 74, 0.7);
}

.Toggle:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 0px;
}

.Toggle.Active {
  background-color: rgba(81, 83, 74, 0.7);
  color: white;
}

.Toggle:hover {
  background-color: rgba(81, 83, 74, 0.2);
}

.Toggle.Active:hover {
  background-color: rgba(81, 83, 74, 1);
}

.PBIExportTitle {
  line-height: normal;
  font-size: 1.7em;
  text-align: center;
}

.PBIExportContent {
  line-height: normal;
  font-size: 1.4em;
  text-align: center;
}

.CopyrightContainer {
  text-align: center;
  font-size: 16px;
  padding: 12px 0;
  color: #000;
}
