.WeatherStation {
  fill: #ffffff;
  opacity: 0.3;
  /* stroke: #fff;
  stroke-width: 1px; */
  filter: drop-shadow(1px 1px 3px #000000a5);
}

.WeatherStation.Active {
  fill: #78be20;
  opacity: 1;
  filter: drop-shadow(1px 1px 3px #00000055);
}

.ChartSVG line {
  stroke: #ccc;
  stroke-width: 1px;
}


.ChartSVG rect {
  filter: drop-shadow(1px 1px 3px #00000055);
}
