.MapTableContainer {
  width: 100%;
  height: calc(100%);
  overflow-y: auto;
  float: right;
  box-sizing: border-box;
}

.MapTable {
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  float: right;
  font-weight: bold;
}

.MapTable th {
  background: rgba(81, 83, 74, 0.8);
  color: white;
}

.MapTable tbody tr th {
  color: white;
  font-weight: bold;
  letter-spacing: 0.3px;
  margin: 2px 0px 2px 0px;
}

.MapTable tr:nth-child(odd) td {
  background: white;
  color: #51534a;
}

.MapTable tr:nth-child(even) td {
  background: white;
  color: #51534a;
}

.MapTable tr th {
  padding: 3px;
  width: 33%;
  font-weight: bold;
}

.MapTable tr td {
  padding: 4px;
  font-weight: normal;
  cursor: pointer;
}

.MapTable tr.active td {
  background: #51534a;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.MapTable tr th.MapTableHeader {
  font-size: 18px;
  padding: 3px;
  font-weight: bold;
}

.MapButtonContainer {
  display: flex;
}

.MapButtonAddContainer {
  position: relative;
}

.MapButtonAdd {
  background: #7e9294;
  border-width: 0 1px 1px 0;
  border-color: #324648;
  background: rgba(81, 83, 74, 0.8);
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MapButtonAddContainer .MapButtonAdd {
  background: rgba(81, 83, 74, 0.8);
  border-width: 0 2px 2px 0;
  border-color: #51534a;
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
}

.MapButtonAdd:hover {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer .Feature {
  background: rgba(81, 83, 74, 0.6);
  border-width: 0px 2px 2px 0;
  border-color: #51534a;
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 4;
  transition: top 250ms;
  opacity: 0;
}

.MapButtonAddContainer.Show .MapButtonAdd {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer.Show .Feature {
  opacity: 1;
}

.MapButtonAddContainer.Show .Feature:nth-child(2) {
  top: 30px;
}

.MapButtonAddContainer.Show .Feature:nth-child(3) {
  top: 60px;
}

.MapButtonAddContainer.Show .Feature:nth-child(4) {
  top: 90px;
}

.MapButtonAddContainer.Show .Feature:nth-child(5) {
  top: 120px;
}

.MapButtonAddContainer.Show .Feature:nth-child(6) {
  top: 150px;
}

.MapButtonAddContainer.Show .Feature:nth-child(7) {
  top: 180px;
}

.MapButtonAddContainer .Feature:hover {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer .Feature:active {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer.Show .MapButtonIcon {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.MapEditor {
  height: 100%;
  box-sizing: border-box;
}

.MapEditor .LeftToolbar {
  color: #51534a;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 8px 32px;
  background: #ffffff33;
  justify-content: space-between;
  overflow-y: auto;
  pointer-events: none;
}
.MapEditor .LeftToolbar .Actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.MapEditor .LeftToolbar .Error {
  padding: 4px 8px;
  background: #c00d1e;
  color: white;
  border-radius: 6px;
  font-weight: 500;

  transition: box-shadow 150ms ease-out 50ms;
}
.MapEditor .MapButton {
  pointer-events: all;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  height: 30px;
  width: 180px;
  background: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: box-shadow 150ms ease-out 50ms;
}
.MapEditor .MapButton.SM {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 2px 8px;
  height: 30px;
  margin-left: 20px;
  width: 160px;
  background: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: box-shadow 150ms ease-out 50ms;
}
.MapEditor .MapButton.XS {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 2px 4px;
  height: 30px;
  margin-left: 28px;
  width: 160px;
  background: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: box-shadow 150ms ease-out 50ms;
}
.MapEditor .MapButton.Half {
  width: 76px;
}
.MapEditor .MapButton.Green {
  color: #ffffff;
  background: #78be20;
}
.MapEditor .MapButton.Gray {
  color: #ffffff;
  background: #51534a;
}
.MapEditor .MapButton.Red {
  color: #ffffff;
  background: #c00d1e;
}
.MapEditor .MapButton.Yellow {
  color: #ffffff;
  background: #f2a900;
}
.MapEditor .MapButton.disabled {
  cursor: unset;
  opacity: 0.65;
}
.MapEditor .MapButton:not(.disabled):hover {
  color: #ffffff;
  background: #78be20;
  box-shadow: 0 0 3px 1px #fff;
}
.MapEditor .RightToolbar {
  color: black;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 8px 32px;
  background: #ffffff33;
  overflow-y: auto;
  pointer-events: none;
}
.MapEditor .InfoBox {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 6px 4px;
  width: 180px;
  background: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  color: black;
  pointer-events: all;
}
.MapEditor .InfoBox div {
  padding: 6px 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.MapEditor
  .InfoBox
  div.PadRow:nth-child(even):not(:first-child):not(:last-child) {
  background: #51534a22;
}
.MapEditor
  .InfoBox
  div.PadRow:nth-child(odd):not(:first-child):not(:last-child) {
  background: #51534a11;
}
.MapEditor .InfoBox .rc-slider-handle {
  margin-top: 0px;
}
.MapEditor .InfoBox .rc-slider-mark {
  display: none;
}
.MapEditor .InfoOverlay {
  display: flex;
  align-items: center;

  padding: 6px 4px;
  background: #ffffffaa;
  color: black;
  position: absolute;
  left: 220px;
  top: 8px;
  border-radius: 6px;
}
.MapEditor .InfoOverlay div {
  padding: 4px 3px;
}
.Spinning {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.1, 0.7, 1, 0.2);
}

.MapEditor .gmnoprint .gmnoprint div {
  margin-left: -204px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
